import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { StateSchema } from "./stateSchema";
import { authReducer } from "./slices/authSlice";
import { chatReducer } from "./slices/chatSlice";
import { subscriptionReducer } from "./slices/subscriptionSlice";

let BACKEND_URL = "http://localhost:8000";

if (process.env.NODE_ENV === "production") {
  BACKEND_URL = "https://" + process.env.REACT_APP_HOST;
}

export const createStore = () => {
  return configureStore<StateSchema>({
    reducer: {
      auth: authReducer,
      chat: chatReducer,
      subscription: subscriptionReducer,
    },
    devTools: process.env.NODE_ENV === "development",
  })
}

export type AppDispatch = ReturnType<typeof createStore>['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();

export { BACKEND_URL };
