import BotResponse from "../BotResponse";

import styles from "./IntroSection.module.css";

type IntroSectionProps = {
  onSubmit: (event: string) => void;
};

const IntroSection = ({onSubmit}: IntroSectionProps) => {
  const items = [
    [
      { title: 'Check domain', command: 'Check hackergpt.app' },
      { title: 'Subdomain Enumeration', command: 'Find all available subdomains for example.com' },
      { title: 'Domain Registration Analysis', command: 'Get domain registration details for hackergpt.app' },
    ],
    [
      { title: 'IP / Domain Geolocation', command: 'Find geolocation for hackergpt.app' },
      { title: 'Dark Web Intelligence', command: 'Search the dark web for any mentions of HackerGPT' },
      { title: 'SSL Security Assessment', command: 'Performs an SSL vulnerability scan on hackergpt.app' },
    ],
  ];

  return (
    <div id={styles.introsection}>
      <h1>
        Welcome to HackerGPT Lite
        <BotResponse response=" - AI Powered OSINT and Discovery Tool" chatLogRef={undefined} />
      </h1>
      <h2>
        An autonomous tool designed to perform OSINT and discovery tasks for penetration testers using a combination of generative AI methodologies.**<span>/</span>
      </h2>
      <p>
        Start your security assessment by giving it a "check hackergpt.app" prompt.**<span>/</span>
      </p>

      <div className={styles.separator}></div>

      {
        items.map((item, index) => <div className={styles.chatContainer} key={index}>
          {
            item.map((i, idx) => <fieldset className={styles.emptyChatTile} key={idx} onClick={() => onSubmit(i.command)}>
              <legend className={styles.emptyChatTitle}> ### {i.title}:/</legend>
              <p className={styles.emptyChatDescription}>{i.command} **/</p>
            </fieldset>)
          }
        </div>)
      }

    </div>
  );
};

export default IntroSection;
