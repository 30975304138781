import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { StateSchema, ThunkConfig } from "../stateSchema";
import { BACKEND_URL } from "../store";

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: localStorage.getItem("user") || "",
    token: localStorage.getItem("token") || "",
    userPicture: localStorage.getItem("userPicture") || "",
  },
  reducers: {
    initAuth: (state, action: PayloadAction<{ user: string, token: string, userPicture: string }>) => {
      state.currentUser = action.payload.user;
      localStorage.setItem('user', action.payload.user);

      state.token = action.payload.token;
      localStorage.setItem('token', action.payload.token);

      state.userPicture = action.payload.userPicture;
      localStorage.setItem('userPicture', action.payload.userPicture);
    },
    logout: (state) => {
      state.currentUser = '';
      localStorage.removeItem('user');

      state.token = '';
      localStorage.removeItem('token');

      state.userPicture = '';
      localStorage.removeItem('userPicture');
    }
  }
})

export const { initAuth, logout } = authSlice.actions;

export const authReducer = authSlice.reducer;

export const getCurrentUser = (state: StateSchema) => state.auth.currentUser;

export const getToken = (state: StateSchema) => state.auth.token;

export const getUserPicture = (state: StateSchema) => state.auth.userPicture;

export const getIsAuthenticated = createSelector(getCurrentUser, getToken, (currentUser, token) => {
  return currentUser && token;
});

export const checkAuthorization = createAsyncThunk<unknown, void, ThunkConfig>('auth/checkAuthorization', async (_, { getState, dispatch }) => {
  try {
    await axios.get(`${BACKEND_URL}/chat/authorization/`, {
      headers: {
        Authorization: `Bearer ${getToken(getState())}`,
      },
    });
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
});
