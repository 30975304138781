import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { StateSchema, ThunkConfig } from "../stateSchema";
import { BACKEND_URL } from "../store";
import { getToken, logout } from "./authSlice";

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    isSubscribed: false,
    userMessageCount: 0,
    numberOfMessagesBeforeCharge: 10,
    isPaymentCardVisible: false,
  },
  reducers: {
    setIsSubscribed: (state, action: PayloadAction<boolean>) => {
      state.isSubscribed = action.payload;
    },
    setUserMessageCount: (state, action: PayloadAction<number>) => {
      state.userMessageCount = action.payload;
    },
    addUserMessageCount: (state, action: PayloadAction<number>) => {
      state.userMessageCount += action.payload;
    },
    setNumberOfMessagesBeforeCharge: (state, action: PayloadAction<number>) => {
      state.numberOfMessagesBeforeCharge = action.payload;
    },
    setIsPaymentCardVisible: (state, action: PayloadAction<boolean>) => {
      state.isPaymentCardVisible = action.payload;
    },
  },
})

export const {
  setIsSubscribed,
  setUserMessageCount,
  addUserMessageCount,
  setNumberOfMessagesBeforeCharge,
  setIsPaymentCardVisible
} = subscriptionSlice.actions;

export const subscriptionReducer = subscriptionSlice.reducer;

export const getIsSubscribed = (state: StateSchema) => state.subscription.isSubscribed;

export const getIsPaymentCardVisible = (state: StateSchema) => state.subscription.isPaymentCardVisible;

export const getUserMessageCount = (state: StateSchema) => state.subscription.userMessageCount;

export const getNumberOfMessagesBeforeCharge = (state: StateSchema) => state.subscription.numberOfMessagesBeforeCharge;

export const getIsUserCharged = createSelector(getIsSubscribed, getUserMessageCount, getNumberOfMessagesBeforeCharge, (isSubscribed, userMessageCount, numberOfMessagesBeforeCharge) => {
  return userMessageCount >= numberOfMessagesBeforeCharge && !isSubscribed
});

export const fetchCurrentSubscription = createAsyncThunk<unknown, void, ThunkConfig>('subscription/fetchCurrentSubscription', async (_, { getState, dispatch }) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/payment/stripe_current_subscription/`, {
      headers: {
        Authorization: `Bearer ${getToken(getState())}`,
      },
    });

    dispatch(setIsSubscribed(response.data.status));
  } catch (error: unknown) {
    dispatch(setIsSubscribed(false));

    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
})

export const fetchUserMessageCount = createAsyncThunk<unknown, void, ThunkConfig>('subscription/fetchUserMessageCount', async (_, { getState, dispatch }) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/chat/message/count/`, {
      headers: {
        Authorization: `Bearer ${getToken(getState())}`,
      },
    });

    dispatch(setUserMessageCount(response?.data?.message_count));
    dispatch(setNumberOfMessagesBeforeCharge(response?.data?.message_limit));
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
});

export const fetchBillingPortal = createAsyncThunk<unknown, void, ThunkConfig>('subscription/fetchBillingPortal', async (_, { getState, dispatch }) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/payment/stripe_billing/`, {
      headers: {
        Authorization: `Bearer ${getToken(getState())}`,
      },
    });

    window.location.href = response.data.url;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
});
