import { useAppDispatch } from "../store/store";
import { setChatLog, createChatSession } from "../store/slices/chatSlice";

const NewChat = () => {
  const dispatch = useAppDispatch();

  return (
    <div
      className="sideMenuButton"
      onClick={() => {
        dispatch(setChatLog([]));
        dispatch(createChatSession());
      }}
    >
      <span>+</span>
      New chat
    </div>
  );
};

export default NewChat;
