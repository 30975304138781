import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import { FaLock } from "react-icons/fa";
import { useAppDispatch } from "../../store/store";
import { getCurrentUser, getUserPicture } from "../../store/slices/authSlice";
import { getChatLog, getPromptInputIsBlocked } from "../../store/slices/chatSlice";
import { getIsUserCharged, setIsPaymentCardVisible } from "../../store/slices/subscriptionSlice";
import ImageViewerModal from "../../components/ImageViewerModal/ImageViewerModal";
import ChatMessageInfo from "../../components/ChatMessageInfo/ChatMessageInfo";
import IntroSection from "../../components/IntroSection/IntroSection";
import Loading from "../../components/Loading";

type ChatBoxProps = {
  handleSubmit: (e: string) => void;
}

const ChatBox = memo(({ handleSubmit }: ChatBoxProps) => {
  const [inputPrompt, setInputPrompt] = useState<string>("");
  const [isImageModalVisible, setIsImageModalVisible] = useState<boolean>(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<string>('');

  const appDispatch = useAppDispatch();

  const currentUser = useSelector(getCurrentUser);
  const userPicture = useSelector(getUserPicture);
  const chatLog = useSelector(getChatLog);
  const isUserCharged = useSelector(getIsUserCharged);
  const promptInputIsBlocked = useSelector(getPromptInputIsBlocked);

  const chatLogEndRef = useRef<HTMLDivElement>(null);

  const isPaymentRequired = (): boolean => {
    if (currentUser?.split("@")[1] === "whitehacklabs.com") {
      return false;
    }

    return isUserCharged;
  };

  // Scroll to the bottom of the chat log when new messages are added
  useEffect(() => {
    if (chatLogEndRef.current) {
      chatLogEndRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    }

    const codeBlocks = document.querySelectorAll('code');
    codeBlocks.forEach((codeBlock) => {
      codeBlock.addEventListener('click', handleCodeCopy);
    });

    return () => {
      codeBlocks.forEach((codeBlock) => {
        codeBlock.removeEventListener('click', handleCodeCopy);
      });
    }
  }, [chatLog]);

  const handleOpenImageModal = (imageSrc: string): void => {
    setSelectedImageSrc(imageSrc);
    setIsImageModalVisible(true);
  }

  const handleCloseImageModal = (): void => {
    setSelectedImageSrc('');
    setIsImageModalVisible(false);
  }

  const preprocessMessage = (message: string): string => {
    // Regex for detecting the [link](link) pattern
    const onionLinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]*\.onion[^\)]*)\)/g;
    // Regex for detecting plain .onion links
    const plainOnionLinkRegex = /(https?:\/\/[^\s]*\.onion[^\s]*)/g;

    // Remove the **URL:** pattern
    if (message?.includes("dark")) {
      message = message?.replace(/\*\*.*?URL:\*\*/g, "");
    }

    // First, replace the [link](link) pattern with code block
    message = message?.replace(onionLinkRegex, (match, text, url) => {
      return `\`\`\` ${url} \`\`\``;
    });

    if (message?.includes("``` ") && message?.includes("dark")) {
      return message;
    }

    // If not already been replaced, replace plain .onion URLs with code blocks
    message = message?.replace(plainOnionLinkRegex, (match, url) => {
      // Check if the URL is already wrapped in a code block
      if (url.startsWith('http://') || url.startsWith('https://')) {
        return `\`\`\` ${url} \`\`\``;
      }
      return url;
    });

    return message;
  }

  // Function to handle the copy action
  const handleCodeCopy = (event: any): void => {
    const codeContent = event.target.textContent;

    // Use the Clipboard API to copy text
    navigator.clipboard.writeText(codeContent)
      .then(() => {
        // Show the toast notification
        toast.info('Link copied to clipboard!');
      });
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!promptInputIsBlocked && inputPrompt.trim() !== '') {
      handleSubmit(inputPrompt);

      // Hide the keyboard in mobile devices
      e.currentTarget.querySelector('input')?.blur();

      setInputPrompt(''); // Clear input after submitting
    } else {
      toast.info('Please wait for response or select another session');
    }
  };

  const handleOpenPaymentCard = (): void => {
    appDispatch(setIsPaymentCardVisible(true));
  }

  return (
    <section className="chatBox">
      {chatLog.length > 0 ? (
        <div className="chatLogWrapper">
          {chatLog.map((chat, idx) => (
            <div className="chatLog" key={idx} id={`chat-${idx}`}>

              {/* User message */}
              {chat?.role === "user" &&
                <div className="chatPromptMainContainer">
                  <div className="chatPromptWrapper">
                    <ChatMessageInfo
                      username={`/${currentUser}`}
                      userColor={'#3ebac6'}
                      timestamp={chat.timestamp}
                      userPicture={userPicture}
                    />
                    <div id="chatPrompt" className="chatPrompt">{chat.message}<span>/</span></div>
                  </div>
                </div>
              }

              {/* Bot response */}
              {chat?.role === "assistant" &&
                <div className="botMessageMainContainer">
                  <div className="botMessageWrapper">
                    <ChatMessageInfo username={'/HackerGPT'} userColor={'#c03fc1'} timestamp={chat.timestamp} />
                    {chat.message === "Running..." ? (
                      <Loading />
                    ) : (
                      <div id="botMessage" className="botMessage">
                        <ReactMarkdown>
                          {preprocessMessage(chat?.message)}
                        </ReactMarkdown>
                        {chat.images.length > 0 && (
                          <div className="imageGrid">
                            {chat.images.map((image, idx) => (
                              <img
                                className="screenshot"
                                key={idx}
                                src={image.image_url}
                                alt={chat.message?.slice(0, 400) + "..."}
                                onClick={() => handleOpenImageModal(image.image_url)}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          ))}
          <div ref={chatLogEndRef} />{" "}
          {/* Invisible element to scroll into view */}
        </div>
      ) : (
        <IntroSection onSubmit={handleSubmit} />
      )}

      <ImageViewerModal
        imageSrc={selectedImageSrc}
        isOpen={isImageModalVisible}
        onClose={handleCloseImageModal}
      />

      <form onSubmit={handleSubmitForm}>
        <div className="inputPromptWrapper" style={{ position: "relative" }} onClick={() => (isPaymentRequired()) ? handleOpenPaymentCard() : {}}>
          <span>&#8594;</span>
          <input
            name="inputPrompt"
            id=""
            className="inputPrompttTextarea"
            type="text"
            value={inputPrompt}
            placeholder="Message HackerGPT"
            onChange={(e) => setInputPrompt(e.target.value)}
            autoFocus
            disabled={isPaymentRequired()}
            style={{ filter: (isPaymentRequired()) ? "blur(2px)" : "" }}
          ></input>
          {isPaymentRequired() &&
            <>
              <b
                className="inputLockArea"
                style={{
                  position: 'absolute',
                  right: '50%',
                  top: '50%',
                  transform: 'translate(50%, -50%)',
                  color: '#888',
                  zIndex: 10,
                  cursor: 'pointer'
                }}
              >
                Subscribe to continue
                <FaLock style={{ marginLeft: "5px" }} />
              </b>

            </>
          }

          <button aria-label="form submit" type="submit" style={{ filter: (isPaymentRequired()) ? "blur(2px)" : "" }}>
            <svg
              fill="#ADACBF"
              width={15}
              height={20}
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#212023"
              strokeWidth={0}
            >
              <title>{"submit form"}</title>
              <path
                d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                stroke="none"
              />
            </svg>
          </button>
        </div>
      </form>
    </section>
  )
});

export default ChatBox;
