import axios from "axios";
import { BACKEND_URL } from "../store/store";

export const fetchProducts = async (token) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/payment/stripe_products/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw Error();
    }
  }
}

export const createCheckoutSession = async (token, product_id) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/payment/stripe_checkout/`, {
      product_id: product_id,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw Error();
    }
  }
};
