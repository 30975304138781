import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../store/store";
import { logout } from "../store/slices/authSlice";
import { deleteChatSession } from "../store/slices/chatSlice";
import { setIsPaymentCardVisible, fetchBillingPortal } from "../store/slices/subscriptionSlice";

type NavLinksProps = {
  svg: ReactNode;
  text: string;
  link?: string;
}

const NavLinks = ({ svg, text, link = '' }: NavLinksProps) => {
  const dispatch = useAppDispatch();

  const handleClick = (text: string) => {
    if (text === 'Clear Conversation') {
      dispatch(deleteChatSession());
    } else if (text === 'Upgrade') {
      dispatch(setIsPaymentCardVisible(true));
    } else if (text === 'Manage Subscription') {
      dispatch(fetchBillingPortal());
    } else if (text === 'Log out') {
      dispatch(logout());
    }
  };

  return (
    <Link
      to={link}
      target={link && "_blank"}
      rel="noreferrer"
      style={{ textDecoration: "none" }}
      onClick={() => handleClick(text)}
    >
      <div className="navPrompt">
        {svg}
        <p>{text}</p>
      </div>
    </Link>
  );
};

export default NavLinks;
