import { memo } from "react";
import { useSelector } from "react-redux";
import { getSessionHistory } from "../../store/slices/chatSlice";
import NavLinksContainer from "../NavLinksContainer";
import NavPrompt from "../NavPrompt";
import NewChat from "../NewChat";
import logo from "../../assets/images/Hacker-GPT-KO.png";

import styles from "./NavContent.module.css";


const NavContent = memo(() => {
  const sessionHistory = useSelector(getSessionHistory);
  return (
    <>
      <img src={logo} alt="HackerGpt Lite" className={styles.logo} />
      <NewChat />
      <div className={styles.navPromptWrapper}>
        {sessionHistory.map(
          (session) =>
            <NavPrompt
              id={session.id}
              chatPrompt={session.first_message ? session.first_message : "New session"}
              key={session.id}
            />
        )}
      </div>
      <NavLinksContainer />
    </>
  );
});

export default NavContent;
