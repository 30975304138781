import { ReactNode } from "react"
import { Provider } from "react-redux";
import { createStore } from "./store";

type StoreProviderProps = {
  children: ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const store = createStore();

  return <Provider store={store}>{children}</Provider>
}
