import "react-toastify/dist/ReactToastify.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store/store";
import {
  getCurrentSessionId,
  addWSMessageToChatLog,
  fetchChatSessionHistory,
  fetchChatLog,
  addUserMessageToChatLog,
  resetSessionsWithBlockedUserPrompt,
} from "../store/slices/chatSlice";
import {
  getIsPaymentCardVisible,
  getIsUserCharged,
  addUserMessageCount,
  setIsPaymentCardVisible,
  fetchCurrentSubscription,
  fetchUserMessageCount
} from "../store/slices/subscriptionSlice";
import { checkAuthorization, getCurrentUser, getToken } from "../store/slices/authSlice";
import NavContent from "../components/NavContent/NavContent";
import SubscriptionModal from "../components/SubscriptionModal/SubscriptionModal";
import Loader from "../components/Loader";
import ChatBox from "../components/ChatBox/ChatBox";
import logo from "../assets/images/Hacker-GPT-KO.png";

const Home = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isWebSocketOpen, setIsWebSocketOpen] = useState<boolean>(false);

  const wsRef = useRef<WebSocket | null>(null); // WebSocket reference

  const dispatch = useAppDispatch();

  const currentUser = useSelector(getCurrentUser);
  const token = useSelector(getToken);
  const currentSessionId = useSelector(getCurrentSessionId);
  const isPaymentCardVisible = useSelector(getIsPaymentCardVisible);
  const isUserCharged = useSelector(getIsUserCharged);

  let websocket_url = "ws://localhost:8000";

  if (process.env.NODE_ENV === "production") {
    websocket_url = "wss://" + process.env.REACT_APP_HOST;
  }

  const reconnectDelay = 1000; // Delay in milliseconds before reconnecting
  let isReconnecting = useRef(false);

  const connectWebSocket = useCallback(async () => {
    // Check if there's an existing WebSocket connection
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      console.log("WebSocket is already connected");
      return;
    }

    dispatch(checkAuthorization());
    
    wsRef.current = new WebSocket(`${websocket_url}/ws/chat/?token=${token}`);

    dispatch(resetSessionsWithBlockedUserPrompt());

    wsRef.current.onopen = (event) => {
      console.log("Connection established", event);
      isReconnecting.current = false; // Reset reconnecting flag
      setIsWebSocketOpen(true);
    };

    wsRef.current.onmessage = (event) => {
      console.log('ws message received', event);
      const data = JSON.parse(event.data);
      
      const newChatLogEntry = {
        role: "assistant",
        message: data?.response?.replace(/\n{2,}/g, '\n'),
        timestamp: new Date().toUTCString(),
        chat_session: data?.session_id,
        images: data?.images,
        id: data?.last_message
      };

      dispatch(addWSMessageToChatLog(newChatLogEntry));
      dispatch(addUserMessageCount(1));

      if (data?.response?.includes("exceeded your free limit")) {
        dispatch(setIsPaymentCardVisible(true));
      }
    };

    wsRef.current.onerror = (event) => {
      console.log('Websocket error', event)
      setIsWebSocketOpen(false);
    };

    wsRef.current.onclose = (event) => {
      console.log("Connection closed", event);
      
      if (!event.wasClean) {
        isReconnecting.current = true; // Set reconnecting flag
        setIsWebSocketOpen(false);
        setTimeout(() => {
          if (token) {
            console.log("Attempting to reconnect...");
            connectWebSocket();  // Attempt to reconnect
          }
        }, reconnectDelay);
      }
    };
  }, [dispatch, token, websocket_url]);

  useEffect(() => {
    // Check if the URL has the query parameter 'subscribed=success'
    const urlParams = new URLSearchParams(window.location.search);
    const isSubscribedSuccess = urlParams.get('subscribed') === 'success';
    connectWebSocket();
    if (isSubscribedSuccess) {
      // If 'subscribed=success', delay the calls by 3 seconds so stripe webhook can update the database
      setTimeout(() => {
        dispatch(fetchCurrentSubscription());
        dispatch(fetchUserMessageCount());
      }, 3000);
    } else {
      // Otherwise, execute the calls immediately
      dispatch(fetchCurrentSubscription());
      dispatch(fetchUserMessageCount());
    }

    dispatch(fetchChatSessionHistory());

    return () => {
      if (wsRef.current) {
        wsRef.current.onclose = () => {};
        wsRef.current.close(); // Clean up WebSocket connection on unmount
        wsRef.current = null;
      }
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchChatLog());
  }, [dispatch, currentSessionId]);


  const handleSubmit = useCallback((event: string): void => {
    dispatch(addUserMessageToChatLog(event));
    wsRef.current?.send(JSON.stringify({ message: event, user_id: currentUser, session_id: currentSessionId }));
  }, [currentSessionId, currentUser, dispatch]);

  console.log(' Current session id:', currentSessionId);
  
  return (
    <div className="App">
      <ToastContainer
        theme="dark"
        position="top-right"
        autoClose={3000}
      />
      <header className="menu-bg">
        <div className="menu">
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <img style={{ width: "40%", marginLeft: "auto", marginRight: "auto", marginBottom: "5px" }} src={logo} alt="HackerGpt Lite" />
      </header>

      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}

      <aside className="sideMenu">
        <NavContent />
      </aside>

      {!isWebSocketOpen ? <Loader /> : null}

      <ChatBox handleSubmit={handleSubmit} />

      {(isPaymentCardVisible || isUserCharged) &&
        <SubscriptionModal isOpen={isPaymentCardVisible} />
      }
    </div>
  );
};

export default Home;
