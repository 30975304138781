import "./normal.css";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "./pages/Home";
import Login from "./pages/Login/Login";
import Authenticating from "./pages/Authenticating";
import { getIsAuthenticated } from "./store/slices/authSlice";


function App() {
  const isAuthenticated = useSelector(getIsAuthenticated);

  const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return isAuthenticated ? <>{children}</> : <Navigate to="auth/login" />;
  };

  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route path="auth/google-oauth2/login/callback" element={<Authenticating />} />
      <Route path="auth/login" element={<Login />} />
    </Routes>
  );
}

export default App;
