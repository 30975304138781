import PulseLoader from "react-spinners/PulseLoader";

const Loader = () => {
  // const override = {
  //     color: "#fff",
  //     loading: true,
  //   };
  return (
    <div className="loader-overlay">
      <div className="loader">
        <PulseLoader
          color={"#fff"}
          loading={true}
          size={5}
          aria-label="Connecting"
          data-testid="loader"
        />
      </div>
    </div>
  )
};

export default Loader;
