import Avatar from "../Avatar/Avatar";

import styles from './ChatMessageInfo.module.css';

type ChatMessageInfoProps = {
  username: string;
  userColor: string;
  timestamp: string;
  userPicture?: string;
}

const ChatMessageInfo = ({ username, userColor, timestamp, userPicture }: ChatMessageInfoProps) => {
  const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);

    return date.toLocaleString('en', { month: 'short' }) + '  ' + date.getDate() + '  ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
  }

  const handleImgError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = '/user.png'
  }

  const isBot = username.includes("HackerGPT");

  return (
    <div className={styles.wrapper}>
      <Avatar>
        {/* User avatar */}
        {isBot ?
          <img className="hgpt-avatar" src="/hgpt.png" alt="hgpt avatar " />
          :
          <img className="user-avatar" src={userPicture} onError={handleImgError} alt="user avatar" />
        }
      </Avatar>
      <div className="hide-on-mobile-screens">
        <div className={styles.messageInfo}>
          <div className={isBot ? styles.botname : styles.username} style={{ color: userColor }}>{username}</div>
          <div className={styles.timestamp}>{formatTimestamp(timestamp)}</div>
        </div>
      </div>
    </div>
  )
}

export default ChatMessageInfo;